import styled from 'styled-components'
import { colors } from '../../utils/global'

export const Wrapper = styled.div`
  display: flex;
  max-width: 920px;
  align-items: stretch;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 60px;
  justify-content: flex-start;
`

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 42px;
  }

  h2 {
    margin: 0;
    color: ${colors.bayOfMany};
    font-weight: 800;
  }

  p {
    margin: 4px 0 0;
    color: ${colors.bayOfMany};
    font-weight: 600;
  }
`

export const BlogPost = styled.div`
  flex: none;
  width: 33.33333%;
  padding: 8px;

  @media (max-width: 800px) {
    width: 50%;
  }

  @media (max-width: 540px) {
    width: 100%;
  }
`

export const Article = styled.article`
  border: 1px solid #707070;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a:first-child {
    width: 48px;
    margin-right: 12px;

    img {
      width: 100%;
    }
  }

  & > div {
    width: calc(100% - 60px);

    p {
      margin: 0 0 12px;
    }
  }

  .footer {
    display: flex;
    width: 100%;
    align-items: center;

    p {
      font-size: 9px;
      margin: 0;
      white-space: nowrap;
      font-weight: 600;
      letter-spacing: 1px;
      color: ${colors.bayOfMany};
    }
    a.more {
      text-decoration: none;
      color: ${colors.bayOfMany};
      display: block;
      text-align: right;
      width: 100%;
    }
  }

  p.excerpt {
    margin-top: 8px;
    font-size: 14px;
    width: 100%;
  }
`

export const ArticleTitle = styled.div`
  width: calc(100% - 60px);

  p {
    margin: 0 0 12px;
    font-weight: 800;

    a {
      text-decoration: none;
      color: ${colors.bayOfMany};
    }
  }
`

export const YellowTab = styled.div`
  background: ${colors.turbo};
  padding: 60px 0 72px;
`
