import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from '../PreviewCompatibleImage/'

import * as S from './styles'
import * as GS from '../GlobalStyles/styles'

import check from '../../img/colegio-exato-check-mark.png'
import news from '../../img/colegio-exato-noticias-icone.svg'

class BlogPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <>
        <S.YellowTab>
          <S.TitleWrapper>
            <img src={check} alt="Check" />
            <h2>Últimas notícias</h2>
            <p>Fique por dentro de tudo no nosso colégio!</p>
          </S.TitleWrapper>
        </S.YellowTab>
        <S.Wrapper>
          {posts &&
            posts.map(({ node: post }) => (
              <S.BlogPost key={post.id}>
                <S.Article>
                  <Link to={post.fields.slug}>
                    <img src={news} />
                  </Link>
                  <S.ArticleTitle>
                    <p>
                      <Link to={post.fields.slug}>
                        {post.frontmatter.title.substring(0, 30)}
                        {post.frontmatter.title.length > 30 && '...'}
                      </Link>
                    </p>
                  </S.ArticleTitle>
                  <p className="excerpt">{post.frontmatter.description}...</p>
                  <div className="footer">
                    <p>{post.frontmatter.date}</p>
                    <Link className="more" to={post.fields.slug}>
                      Leia mais →
                    </Link>
                  </div>
                </S.Article>
              </S.BlogPost>
            ))}
        </S.Wrapper>
      </>
    )
  }
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogPageQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "DD [de]  MMMM, YYYY", locale: "pt")
                description
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogPage data={data} count={count} />}
  />
)
