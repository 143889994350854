import styled from 'styled-components'
import { colors } from '../../utils/global'
import { Link } from 'gatsby'

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  & > div {
    width: 42px;
  }
`

export const Title = styled.h2`
  margin: ${({ margin }) => margin || 0};
  color: ${colors.bayOfMany};
  font-weight: 800;
`

export const Text = styled.p`
  margin: ${({ margin }) => margin || 0};
  text-align: ${({ align }) => align || 'left'};
  font: normal normal 500 18px/22px 'Montserrat';
`

export const YellowButton = styled.a`
  cursor: pointer;
  background: ${colors.turbo};
  color: ${colors.bayOfMany};
  padding: 6px 18px;
  border-radius: 18px;
  margin: ${({ margin }) => margin || 0};
  text-decoration: none;
`

export const YellowLink = styled(Link)`
  cursor: pointer;
  background: ${colors.turbo};
  color: ${colors.bayOfMany};
  padding: 6px 18px;
  border-radius: 18px;
  margin: ${({ margin }) => margin || 0};
  text-decoration: none;
`

export const Divider = styled.div`
  border-top: 2px solid ${colors.bayOfMany};
  display: block;
  height: 0.125rem;
  margin: 40px 0px;
  position: relative;
  text-align: center;

  &:after {
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    color: ${colors.bayOfMany};
    content: attr(data-content);
    display: inline-block;
    font-size: 20px;
    font-weight: 300;
    padding: 0px 0.8rem;
    text-align: center;
    top: 0px;
    transform: translateY(-60%);
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`

export const Contact = styled.div`
  padding: 80px 0;
  width: 95%;
  max-width: 820px;
  margin: 0 auto;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    input,
    textarea {
      background: ${colors.mercury};
      border: none;
      margin: 4px 0;
      border-radius: 8px 12px;
      padding: 8px;
    }

    input[type='button'] {
      width: 200px;
      align-self: center;
      background: ${colors.turbo};
      margin-top: 16px;
      color: ${colors.bayOfMany};
    }
  }
`
