import React from 'react'

import Layout from '../../components/Layout/'
import BlogPage from '../../components/BlogPage'

import * as GS from '../../components/GlobalStyles/styles'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <BlogPage />
      </Layout>
    )
  }
}
